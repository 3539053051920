import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { SMEDiscount } from './types';

export default function SMEDiscountsTable(props: Props) {
  const { discounts } = props;

  return <DataGrid rows={discounts} columns={columns} />;
}

const columns: GridColDef<SMEDiscount>[] = [
  {
    field: 'coupon',
    headerName: 'Coupon',
    flex: 1,
  },
  {
    field: 'plan',
    headerName: 'Plan',
    flex: 1,
  },
  {
    field: 'price',
    headerName: 'Price',
    flex: 1,
    valueGetter: (_value, row) => `£${row.price.toFixed(2)}`,
  },
  {
    field: 'dashboardPrice',
    headerName: 'Dashboard Price',
    flex: 1,
    valueGetter: (_value, row) => `£${row.dashboardPrice.toFixed(2)}`,
  },
  {
    field: 'hasDashboard',
    headerName: 'Has Dashboard',
    flex: 1,
  },
  {
    field: 'used',
    headerName: 'Used',
    flex: 1,
  },
  {
    field: 'geneticsPrice',
    headerName: 'Genetics Price',
    flex: 1,
    valueGetter: (_value, row) =>
      row.geneticsPrice !== undefined ? `£${row.geneticsPrice.toFixed(2)}` : '',
  },
];

interface Props {
  discounts: SMEDiscount[];
}
