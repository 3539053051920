import {
  useGetInvestorUsersQuery,
  useGrantAccessMutation,
  useRevokeAccessMutation,
} from 'common/api';
import Navigation from 'pages/Navigation';
import { InvestorUser } from './types';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMemo, useState } from 'react';

export default function InvestorsScreen() {
  const { data: users } = useGetInvestorUsersQuery();
  const [revokeAccess, { isLoading: revoking }] = useRevokeAccessMutation();
  const [grantAccess, { isLoading: granting }] = useGrantAccessMutation();

  const [selectedUser, setSelectedUser] = useState<string>();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const filteredUsers = useMemo<InvestorUser[]>(() => {
    if (!users) {
      return [];
    }

    return users.filter((user) => {
      if (email && !user.email.toUpperCase().includes(email.toUpperCase())) {
        return false;
      }

      if (name && !user.name.toUpperCase().includes(name.toUpperCase())) {
        return false;
      }

      return true;
    });
  }, [users, email, name]);

  const columns = useMemo<GridColDef<InvestorUser>[]>(() => {
    return [
      {
        field: 'email',
        headerName: 'Email',
        flex: 1,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'authorized',
        headerName: 'Authorized',
        flex: 1,
      },
      {
        headerName: 'Status',
        field: 'status',
        flex: 1,
        renderCell: (params) => {
          const {
            row: { authorized, username },
          } = params;

          return (
            <LoadingButton
              variant="contained"
              className="mr-standard"
              color={authorized ? 'warning' : 'primary'}
              loading={(revoking || granting) && selectedUser === username}
              onClick={() => {
                setSelectedUser(username);
                if (authorized) {
                  revokeAccess(username)
                    .unwrap()
                    .finally(() => setSelectedUser(undefined));
                } else {
                  grantAccess(username)
                    .unwrap()
                    .finally(() => setSelectedUser(undefined));
                }
              }}
            >
              {authorized ? 'Revoke' : 'Grant access'}
            </LoadingButton>
          );
        },
      },
    ];
  }, [grantAccess, granting, revokeAccess, revoking, selectedUser]);

  return (
    <div className="fullPageContainer">
      <Navigation />

      <Stack
        direction={'row'}
        alignItems={'center'}
        className="mx-standard gap-standard"
      >
        <TextField
          label="Email"
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
        />

        <TextField
          label="Name"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
        />
      </Stack>

      {users && (
        <DataGrid
          rows={filteredUsers}
          columns={columns}
          getRowId={(item) => item.username}
        />
      )}
    </div>
  );
}
