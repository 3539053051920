import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import axios, { AxiosError } from 'axios';
import { environment } from 'pages/login/actions';

export const apiClient = axios.create({ baseURL: environment.apiURL });

interface SignOutController {
  signOut?: () => void;
}

export let apiSignOutController: SignOutController = {};

apiClient.interceptors.request.use(async (config) => {
  if (!config || !config.headers) {
    return config;
  }

  try {
    const session = await fetchAuthSession();
    if (!session.tokens?.accessToken) {
      throw new Error('No access token');
    }

    config.headers = {
      ...config.headers,
      'Access-Token': session.tokens.accessToken.toString(),
    };

    return config;
  } catch (e) {
    if (apiSignOutController.signOut) {
      apiSignOutController.signOut();
    }
  }
});

apiClient.interceptors.response.use(
  (response) => response,
  (err) => {
    if ([401, 403].includes((err as AxiosError).response?.status ?? 0)) {
      if (apiSignOutController.signOut) {
        apiSignOutController.signOut();
      }
    }
  }
);
