import { Button } from '@mui/material';
import { signOut } from 'aws-amplify/auth';
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authActions } from 'store/reducers/auth';
import { useAppDispatch } from 'store/store';

export default function Navigation() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onLogoutPressed = useCallback(() => {
    signOut().then(() => {
      dispatch(authActions.setLoggedIn(false));
      navigate('/', { replace: true });
    });
  }, [dispatch, navigate]);

  return (
    <div className="helpNavigation">
      <div className="section">
        <strong>Backoffice</strong>
        <Link to="/users">Users</Link>
        <Link to="/discounts">Discounts</Link>
        <Link to="/sme">SME Discounts</Link>
        <Link to="/investors">Investors</Link>

        <Button
          variant="contained"
          className="marginTop20 marginBottom20"
          onClick={onLogoutPressed}
        >
          Log out
        </Button>
      </div>

      <div className="section">
        <strong>Help</strong>
        <Link to="/help_users">Searching for a user</Link>
        <Link to="/help_discounts">Creating a discount</Link>
        <Link to="/help_discounts_edit">Deactivating users &amp; coupons</Link>
      </div>
    </div>
  );
}
