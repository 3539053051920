import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Stack,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import {
  useCreateDiscountMutation,
  useCreateSMEDiscountMutation,
} from 'common/api';
import { useCallback, useMemo, useState } from 'react';

export default function SMECreateDiscountModal(props: Props) {
  const { geneticsPrice: geneticsPriceProp, onClose } = props;

  const [createDiscount, { isLoading: creatingDiscount }] =
    useCreateSMEDiscountMutation();

  const [planType, setPlanType] = useState<'piki' | 'paka'>('piki');

  const [price, setPrice] = useState('');
  const [customGeneticsPrice, setCustomGeneticsPrice] = useState(false);
  const [dashboardPrice, setDashboardPrice] = useState('');
  const [geneticsPrice, setGeneticsPrice] = useState(
    geneticsPriceProp.toString()
  );

  const [priceError, setPriceError] = useState('');
  const [dashboardPriceError, setDashboardPriceError] = useState('');
  const [geneticsPriceError, setGeneticsPriceError] = useState('');

  const valid = useCallback((value: string) => {
    if (value === '') {
      return false;
    }

    const parsed = parseInt(value);
    return !isNaN(parsed) && parsed > 0;
  }, []);

  const validPrice = useMemo(() => valid(price), [price, valid]);

  const validDashboardPrice = useMemo(
    () => valid(dashboardPrice),
    [dashboardPrice, valid]
  );

  const validGeneticsPrice = useMemo(
    () => valid(geneticsPrice),
    [geneticsPrice, valid]
  );

  const buttonDisabled = useMemo(() => {
    if (planType === 'piki') {
      return !validPrice;
    } else {
      return !validPrice || !validGeneticsPrice || !validDashboardPrice;
    }
  }, [planType, validDashboardPrice, validGeneticsPrice, validPrice]);

  const onCreatePressed = useCallback(() => {
    createDiscount({
      plan: planType,
      price: parseInt(price),
      dashboardPrice:
        dashboardPrice !== '' ? parseInt(dashboardPrice) : undefined,
      geneticsPrice: customGeneticsPrice ? parseInt(geneticsPrice) : undefined,
    })
      .unwrap()
      .then(onClose)
      .catch(() => {
        alert('An error occurred');
      });
  }, [
    createDiscount,
    customGeneticsPrice,
    dashboardPrice,
    geneticsPrice,
    onClose,
    planType,
    price,
  ]);

  return (
    <div className="topModalContainer">
      <div className="discountCoupons createDiscountModal">
        <FormControl className="marginTop40" fullWidth>
          <InputLabel id="discountTypeLabel">Type</InputLabel>

          <Select
            label="Plan type"
            labelId="discountTypeLabel"
            value={planType}
            onChange={({ target: { value } }) => {
              setPlanType(value as 'piki' | 'paka');
            }}
          >
            <MenuItem value="piki">Piki</MenuItem>
            <MenuItem value="paka">Paka</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Price"
          className="textField marginTop20"
          type="number"
          variant="standard"
          error={priceError.length > 0}
          helperText={priceError}
          value={price}
          onChange={({ target: { value } }) => setPrice(value)}
          onFocus={() => setPriceError('')}
          onBlur={() => setPriceError(validPrice ? '' : 'Invalid price')}
        />

        {planType === 'paka' && (
          <>
            <TextField
              label="Dashboard upgrade price"
              className="textField marginTop20"
              type="number"
              variant="standard"
              value={dashboardPrice}
              error={dashboardPriceError.length > 0}
              helperText={dashboardPriceError}
              onChange={({ target: { value } }) => setDashboardPrice(value)}
              onFocus={() => setDashboardPriceError('')}
              onBlur={() =>
                setDashboardPriceError(
                  validDashboardPrice ? '' : 'Invalid price'
                )
              }
            />

            <Stack className="flex-row items-bottom justify-between mt-[30px] gap-standard">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={customGeneticsPrice}
                    onChange={({ target: { checked } }) => {
                      setCustomGeneticsPrice(checked);
                      if (!checked) {
                        setGeneticsPrice(geneticsPriceProp.toString());
                      }
                    }}
                  />
                }
                label="Custom genetics price"
              />

              <TextField
                type="number"
                variant="standard"
                label="Genetics Price"
                className="min-w-[300px]"
                error={geneticsPriceError.length > 0}
                helperText={geneticsPriceError}
                value={geneticsPrice}
                disabled={!customGeneticsPrice}
                onChange={({ target: { value } }) => setGeneticsPrice(value)}
                onFocus={() => setGeneticsPriceError('')}
                onBlur={() =>
                  setGeneticsPriceError(
                    validGeneticsPrice ? '' : 'Invalid price'
                  )
                }
              />
            </Stack>
          </>
        )}

        <Button
          variant="contained"
          className="self-end mt-[40px]"
          disabled={buttonDisabled}
          onClick={onCreatePressed}
        >
          Create
        </Button>
      </div>
    </div>
  );
}

interface Props {
  geneticsPrice: number;
  onClose: () => void;
}
